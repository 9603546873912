import React, { ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import CheckmarkCircleIcon from '../../../../assets/icons/CheckmarkCircleIcon';
import Button from '../../../buttons/Button';
import PrintLocationReportIcon from '../../../../assets/icons/PrintLocationReportIcon';
import ReprintRecallSheetIcon from '../../../../assets/icons/ReprintRecallSheetIcon';

import { PickTour, Pick, PaginationData } from '../../../../types';
import {
  mergeClassNames,
  calculateSkippedQuantity,
  calculatePickedQuantity,
  isCommercialOrder,
} from '../../../../utils';
import { ORDER_TYPE_OVERSTOCK, ORDER_TYPE_RECALL } from '../../../../constants';

import styles from './PickTourComplete.css';

type Props = {
  activePickTour: PickTour;
  paginationData: PaginationData;
  returnToOrderList: () => void;
  printLocationReport: () => void;
  printRecallReport: () => void;
  printDiscrepancyReport: () => void;
  currentLanguage: string;
  labelsToPrint: Number;
};

const PickTourComplete = ({
  activePickTour,
  paginationData,
  returnToOrderList,
  printLocationReport,
  printRecallReport,
  printDiscrepancyReport,
  currentLanguage,
  labelsToPrint,
}: Props): ReactElement => {
  const hasOverstockItems = activePickTour.picks.some(pick => pick.orderType === ORDER_TYPE_OVERSTOCK);
  const hasRecallItems = activePickTour.picks.some(pick => pick.orderType === ORDER_TYPE_RECALL);
  const hasCommercialItems = activePickTour.picks.some(pick => isCommercialOrder(pick.orderType));

  const { t } = useTranslation();
  const translationRoot = 'PICK_TOUR_COMPLETE_OR_EXIT';

  const completedQuantity =
    calculatePickedQuantity(activePickTour) +
    (paginationData.isPaginated ? paginationData.totalItemsPicked - paginationData.initialPageItemsPicked : 0);

  const skippedQuantity = paginationData.isPaginated
    ? paginationData.totalItemsSkipped
    : calculateSkippedQuantity(activePickTour);

  const pickDestinations = activePickTour.picks.reduce(
    (acc: { destination: string | null; quantity: number }[], pick: Pick) => {
      if (pick.pickedQuantity !== 0) {
        const foundDestinationObject = acc.find(accItem => accItem.destination === pick.destination);
        if (!foundDestinationObject) {
          return [...acc, { destination: pick.destination, quantity: pick.pickedQuantity }];
        }
        foundDestinationObject.quantity += pick.pickedQuantity;
      }
      return acc;
    },
    []
  );

  const Message = (): ReactElement => {
    if (hasOverstockItems) {
      return (
        <>
          <p className={styles.overstockMessage} data-cy="pick-tour-complete-overstock-message-1">
            {t(`${translationRoot}.MOVE_INVENTORY`)}
          </p>
          <p className={styles.overstockMessage} data-cy="pick-tour-complete-overstock-message-2">
            {t(`${translationRoot}.RE-SCAN-INVENTORY`)}
          </p>
        </>
      );
    }
    if (hasCommercialItems) {
      return (
        <>
          {pickDestinations.map(destinationObject => (
            <p
              key={destinationObject.destination || ''}
              data-cy="pick-tour-complete-commercial-message"
              className={styles.commMessage}
            >
              {t(`${translationRoot}.PLEASE_STAGE_COMMERCIAL`, {
                quantity: destinationObject.quantity,
                destination: destinationObject.destination,
              })}
            </p>
          ))}
        </>
      );
    }
    if (hasRecallItems) {
      return (
        <>
          <p className={styles.recallMessage} data-cy="pick-tour-complete-recall-message-1">
            <span className={styles.highlight}>{t(`${translationRoot}.RECALL_SHEET_PRINTED`)}</span>
            <span> {t(`${translationRoot}.PLEASE_PLACE_SHEET`)}</span>
          </p>
          <p className={styles.recallMessage} data-cy="pick-tour-complete-recall-message-2">
            {t(`${translationRoot}.PLEASE_ATTACH_LABELS`)}
          </p>
        </>
      );
    }
    return (
      <>
        <p className={styles.sat2satMessage} data-cy="pick-tour-complete-sat2sat-message-1">
          {t(`${translationRoot}.${skippedQuantity > 0 ? 'SAT2SAT_TRANSFER_INITIATED' : 'SAT2SAT_SHEET_PRINTED'}`)}
        </p>
        <p className={styles.sat2satMessage} data-cy="pick-tour-complete-sat2sat-message-2">
          {t(`${translationRoot}.${skippedQuantity > 0 ? 'SHEET_WILL_PRINT' : 'PLEASE_STAGE_SAT2SAT'}`)}
        </p>
      </>
    );
  };

  const nonPickableQuantity = activePickTour.picks.filter(pick => !pick.isEligibleForPicking).length;

  const lineItems = [
    {
      label: 'ITEMS_PICKED',
      subLabel: '',
      quantity: completedQuantity,
    },
    ...(hasRecallItems
      ? [
          {
            label: 'LABELS_PRINTED',
            subLabel: '',
            quantity: labelsToPrint,
          },
        ]
      : [
          {
            label: 'ITEMS_RETURNED',
            subLabel: '',
            quantity: 0,
          },
          {
            label: hasOverstockItems && paginationData.isPaginated ? 'ITEMS_NOT_FOUND' : 'ITEMS_SKIPPED',
            subLabel: '',
            quantity: skippedQuantity,
          },
        ]),
    ...(nonPickableQuantity > 0
      ? [
          {
            label: 'NON_PICKABLE_ITEMS',
            subLabel: 'NON_PICKABLE_ITEMS_INCLUDE',
            quantity: nonPickableQuantity,
          },
        ]
      : []),
  ];

  return (
    <section className={styles.section}>
      <article>
        <div className={styles.quantityLine}>
          <CheckmarkCircleIcon />
          <h6 className={mergeClassNames(styles.quantity, currentLanguage === 'es-MX' && styles.quantitySpanish)}>
            {t(`${translationRoot}.COMPLETE`)}
          </h6>
        </div>
        {lineItems.map(line => (
          <div className={styles.lineItem} key={`${line.label}-${line.quantity}`}>
            <span>{t(`${translationRoot}.${line.label}`)}</span>
            <h6 className={styles.lineItemCount}>{line.quantity}</h6>
            <div className={styles.lineNonPickable}>{t(`${translationRoot}.${line.subLabel}`)}</div>
          </div>
        ))}
        <Message />
        {!!nonPickableQuantity && (
          <div className={styles.attentionMessage}>
            <span>{t(`${translationRoot}.NON_PICKABLE_INVOICE_INCLUDED`)}</span>
          </div>
        )}
        <div className={styles.printButtons}>
          {hasOverstockItems && (
            <>
              <div className={styles.printButton} onClick={printLocationReport}>
                <PrintLocationReportIcon />
                <div className={styles.printButtonLabel}>{t(`${translationRoot}.PRINT_LOCATION`)}</div>
              </div>
              <div className={styles.printButton} onClick={printDiscrepancyReport}>
                <ReprintRecallSheetIcon />
                <div className={styles.printButtonLabel}>{t(`${translationRoot}.REPRINT_DISCREPANCY_REPORT`)}</div>
              </div>
            </>
          )}
          {hasRecallItems && (
            <>
              <div className={styles.printButton} onClick={printRecallReport}>
                <ReprintRecallSheetIcon />
                <div className={styles.printButtonLabel}>{t(`${translationRoot}.REPRINT_RECALL_SHEET`)}</div>
              </div>
            </>
          )}
        </div>
      </article>
      <footer>
        <Button data-cy="pick-tour-complete-button" onClick={returnToOrderList}>
          {t(`${translationRoot}.RETURN`)}
        </Button>
      </footer>
    </section>
  );
};

export default PickTourComplete;
